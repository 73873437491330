/** @jsxImportSource theme-ui */
import { Container, Box, Grid, Text, Heading } from 'theme-ui';
import Image from 'components/image';
import SectionHeader from 'components/section-header';
import TextFeature from 'components/text-feature';
import FeatureCard from 'components/feature-card.js';
import Smart from 'assets/feature/smart.svg';
import Winner from 'assets/feature/winner.svg';
import Cloud from 'assets/feature/cloud.svg';
import Setting from 'assets/feature/setting.svg';
import Design from 'assets/feature/design.svg';
import Chat from 'assets/feature/chat.svg';
import CoreFeatureThumb from 'assets/device_frame_admin_sized_generic.png';
import Secure from 'assets/core-feature/secure.svg';

const data = {
  providerFearures: [
    {
      id: 1,
      imgSrc: Design,
      altText: 'Compatibility',
      title: 'Ultrasound Machine Compatibility',
      text:
        'Theia Stream is compatible with all ultrasound machines.',
    },
    {
      id: 2,
      imgSrc: Setting,
      altText: 'Revenue',
      title: 'New Revenue Stream',
      text:
        'We do not control what, or if, you charge for using Theia Stream. Find a price that works best for you and your clients.',
    },
    {
      id: 3,
      imgSrc: Winner,
      altText: 'Business',
      title: 'Grow Business',
      text:
        'Offering Theia Stream gives your business a competitive advantage.',
    },
    {
      id: 4,
      imgSrc: Cloud,
      altText: 'Automated',
      title: 'Automated',
      text:
        'Spend more time focusing on your business. Clients automatically receive an email when their video is ready to view and when images are added.',
    },
    {
      id: 5,
      imgSrc: Smart,
      altText: 'Smart',
      title: 'Save Time and Money',
      text:
        'No more buying and wasting time with DVDs, CDs, or Flash Drives.',
    },
    {
      id: 6,
      imgSrc: Chat,
      altText: 'Support',
      title: 'Customer Support',
      text:
        'Contact us easily by phone or email to receive fast support for any issues or questions.',
    }
  ],
  platformFeatures: [
    {
      id: 1,
      imgSrc: Secure.src,
      altText: 'Easy to use',
      title: 'Manage schedule',
      text:
        'Manage sessions, schedules, and upload images directly from our easy to use admin dashboard.',
    },
    {
      id: 2,
      imgSrc: Secure.src,
      altText: 'Secure',
      title: 'Secure',
      text:
        'Content is encrypted and secured with an access code to prevent unauthorized viewing.',
    },
    {
      id: 3,
      imgSrc: Secure.src,
      altText: 'Trusted',
      title: 'Used in many ultrasound facilities all around the country',
      text:
        'Established in 2017, Theia Stream is trusted in the ultrasound industry. Over 2,000,000 images and videos provided through our platform across more than 100,000 ultrasound sessions',
    },
    {
      id: 4,
      imgSrc: Secure.src,
      altText: 'Compatible with all modern devices',
      title: 'Compatible',
      text:
        'User-friendly web app is compatible with all modern devices and browsers.',
    }
  ]
};

export default function Feature() {
  return (
    <section sx={{ variant: 'section.feature' }}>
      <Container>
        <SectionHeader
          slogan="provider benefits"
          title="Run a more efficient business"
        />

        <Grid sx={styles.grid}>
          {data.providerFearures.map((item) => (
            <FeatureCard
              key={item.id}
              src={item.imgSrc.src}
              alt={item.title}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>

      <Container sx={styles.containerBox}>
        <Box sx={styles.thumbnail}>
          <Image src={CoreFeatureThumb.src} alt="Thumbnail" />
        </Box>
        <Box sx={styles.contentBox}>
          <Box sx={styles.headingTop}>
            <TextFeature subTitle={data.subTitle} title={data.title} />
          </Box>

          <Grid gap="15px 0" columns={1} sx={styles.grid1}>
            {data.platformFeatures.map((item) => (
              <Box sx={styles.card} key={item.id}>
                <Image src={item.imgSrc} alt={item.altText} sx={styles.img} />

                <Box sx={styles.wrapper}>
                  <Heading sx={styles.wrapper.title}>{item.title}</Heading>
                  <Text sx={styles.wrapper.subTitle}>{item.text}</Text>
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, null, 3],
    gridGap: [
      '37px 0',
      null,
      '45px 30px',
      null,
      '50px 30px',
      null,
      null,
      '90px 70px',
    ],
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    title: {
      fontSize: 3,
      color: 'heading_secondary',
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, null, 3],
    },

    subTitle: {
      fontSize: 1,
      fontWeight: 400,
      lineHeight: [1.85, null, 2],
    },
  },
  containerBox: {
    mt: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: ['column', null, null, 'row'],
  },
  thumbnail: {
    pl: [0, 5, 0, null, 7, 95],
    pr: [0, 5, null, null, null, 75, 95],
    order: [2, null, null, 0],
  },
  contentBox: {
    width: ['100%', 450, 550, 350, 500, 570],
    pr: [0, null, 'auto', null, null, 80],
    pl: 'auto',
    flexShrink: 0,
  },
  grid1: {
    p: ['0 0px 35px', null, null, null, '0 20px 40px', null, '0 40px 40px', 0],
  },
  card: {
    display: 'flex',
    alignItems: 'flex-start',
    p: [
      '0 17px 20px',
      null,
      null,
      '0 0 20px',
      '20px 15px 17px',
      null,
      null,
      '25px 30px 23px',
    ],
    backgroundColor: 'white',
    borderRadius: '10px',
    transition: 'all 0.3s',
    width: ['100%', '85%', null, '100%'],
    mx: 'auto',
    '&:hover': {
      boxShadow: [
        '0px 0px 0px rgba(0, 0, 0, 0)',
        null,
        null,
        null,
        '0px 8px 24px rgba(69, 88, 157, 0.07)',
      ],
    },
  },

  img: {
    width: ['50px', null, '55px'],
    height: 'auto',
    flexShrink: 0,
    mr: [3, 4],
  },
};
