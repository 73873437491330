import React from 'react';
import { ThemeProvider } from 'theme-ui';
import { StickyProvider } from 'contexts/app/app.provider';
import theme from 'theme';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import KeyFeature from 'sections/key-feature';
import Feature from 'sections/feature';
import WorkFlow from 'sections/workflow';
import Package from 'sections/package';
import Contact from 'sections/contact';

export default function IndexPage() {
  return (
   
      <ThemeProvider theme={theme}>
        <StickyProvider>
          <Layout>
            <SEO title="Theia Stream" />
            <Banner />
            <section id="feature">
              <KeyFeature />
              <WorkFlow />
              <Feature />
            </section>
            <Package />
            <Contact />
          </Layout>
        </StickyProvider>
      </ThemeProvider>
  );
}
