/** @jsxImportSource theme-ui */
import React, { useState, useRef } from 'react';
import SectionHeader from '../components/section-header';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReCAPTCHA from "react-google-recaptcha"
import Alert from 'react-bootstrap/Alert';
import { Container as RContainer } from 'react-bootstrap';
import { API_BASE_URL, RECAPTCHA_KEY } from "config"
import axios from 'axios'

export default function Contact() {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const captchaRef = useRef(null)

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            setLoading(true)
            setErrorMessage('')
            const captchaToken = await captchaRef.current.executeAsync()
            captchaRef.current.reset()
            try {
                const response = await axios.post(`${API_BASE_URL}/contact`,
                    {
                        name: name,
                        email: email,
                        message: message,
                        captchaToken: captchaToken
                    })
                setLoading(false)
                switch (response.status) {
                    case 201:
                        setSuccess(true)
                        break
                    case 401:
                        setErrorMessage('Oh no, it looks like you might be a robot. Please try again.')
                    default:
                        setErrorMessage('Oops, something went wrong. Please try again.')
                }
            } catch (ex) {
                setErrorMessage('Oops, something went wrong. Please try againsdfsdf.')
            }
        }

        setValidated(true);

    };

    return (
        <section id="contact" sx={styles.coreFeature} >
            <SectionHeader
                slogan="Contact Us"
                title="Contact us to get started today!"
            />
            <RContainer>
                <Row>
                    <Col>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name (optional)"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom03">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        placeholder="Enter message..." required
                                        value={message}
                                        onChange={e => setMessage(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid message.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            {!loading && !success &&
                                <Button className="float-end" type="submit" variant="success">Send message</Button>
                            }

                            {loading &&
                                <ProgressBar variant="success" animated now={100} />
                            }

                            {success &&
                                <Alert variant="success">
                                    <strong>Success!</strong> Your message has been sent. We will respond as soon as possible.
                                </Alert>
                            }

                            {errorMessage != '' &&
                                <Alert variant="danger">
                                    <strong>Send Message Failed!</strong> <p>{errorMessage}</p>
                                </Alert>
                            }
                            <RContainer>
                                <Row>
                                    <Col md={{ span: 2, offset: 10 }}>
                                        <ReCAPTCHA size="invisible" sitekey={RECAPTCHA_KEY} ref={captchaRef} />
                                    </Col>
                                </Row>
                            </RContainer>
                        </Form>
                    </Col>
                </Row>
            </RContainer>
        </section>
    );
}


const styles = {
    coreFeature: {
        pt: [8, null, 9, null, null, 10],
        pb: [1, null, 2, null, null, 3],
        position: 'relative',
        '&::before': {
            position: 'absolute',
            content: '""',
            top: ['auto', null, null, '50%'],
            bottom: ['100px', 0, null, 'auto']
        },
    },
    containerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: ['column', null, null, 'row'],
    },
    thumbnail: {
        pl: [0, 5, 0, null, 7, 95],
        pr: [0, 5, null, null, null, 75, 95],
        order: [2, null, null, 0],
    },
    contentBox: {
        width: ['100%', 450, 550, 350, 500, 570],
        pr: [0, null, 'auto', null, null, 80],
        pl: 'auto',
        flexShrink: 0,
    },
    headingTop: {
        pl: [0, null, null, null, '35px', null, '55px', 6],
        mb: [3, null, null, null, 1],
        textAlign: ['center', null, null, 'left'],
    }
}